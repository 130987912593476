
import React from 'react';
import _ from 'lodash';

function CategoryItems(props) {
  const {view_only_stock_orders, category, first, last, handleNewStockCategory, currentCategory} = props
  let depth = parseInt(props.depth)

  return (<>
    <li className={`${depth > 1 ? "d-none": ""} ${first} ${last} dropdown-item category_container p-0 mb-2`} data-id = {category.id} data-full-name = {category.full_name || category.name} data-parent-id= {category.parent_id} data-name = {category.name} data-depth-level= {depth}>
      <a className={`${depth > 1 ? "other_category" : ""} ${depth == 0 ? " first_level font_size_first_category" : "font_size_other_category"} add-line-height-30 searchable_field_for_categoy d-flex`} style={{ borderLeft: (depth == 0 &&`5px solid ${category.color}`), marginLeft: (depth > 1 && `${depth*20-15+((depth-2)*5)}px`) }}>
        <div className={`col-12 ${depth > 0 ? "ps-3" : "ps-2"} d-flex`} data-id = {category.id} data-full-name = {category.full_name || category.name}>
          <div className='flex-grow-1' onClick={(e) => currentCategory ? handleNewStockCategory(category.id, currentCategory) : handleNewStockCategory(category.id)}>
            <span data-id = {category.id} data-full-name = {category.full_name || category.name}>
              {category.name}
            </span>
            {(category.children.length>0 && depth > 0) &&
            <>
              <span className="fa fa-circle separator_style font_size_4"/>
              <span count={`${category.children.length}`}> {`${category.children.length}`}</span>
              </>
            }
          </div>
          <div>
            {(category.children.length>0 && depth > 0) &&
            <>
              {depth == 0 ?
              <span className="add_padding_top_2 float-end" data-id = {`${category.id}`}/>
              :
              <span className='add_padding_top_2 float-end show_hidden_children_category on fa fa-plus' data-id = {`${category.id}`} style={{fontSize: "larger", lineHeight: "30px", marginRight: (depth > 1 && `${depth*20-15+((depth-2)*5)}px`)}}/>
              }
              </>
            }
          </div>
        </div>
      </a>
    </li>
    <>
    {category.children.length > 0 &&
      category.children.map((children, i) => (
        <CategoryItems view_only_stock_orders = {view_only_stock_orders} category = {children} depth = {depth+1} i = {i} first= {`${(i == 0 && depth > 0) ? 'first' : ''}`} last= {`${( i == (category.children).length - 1 && depth > 0 ) ? 'last' : ''}`} handleNewStockCategory={handleNewStockCategory} currentCategory={currentCategory}/>
      ))
    }
    </>
    </>
  )
} 
export default CategoryItems