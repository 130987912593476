import React, { PureComponent } from 'react'
import GsgButton from '../../shared/components/gsg_button'
import PropTypes from 'prop-types'

class Footer extends PureComponent {
  constructor(props) {
    super(props)
    
  }
  
  render() {

    return (
        
        <div className='row bottom_buttons_bar'> 
          <div className="col-12 py-2 px-3">
            {!this.props.view_only_stock_orders ? (
              <div>
              <GsgButton text="Enter New Stock Order"
                    className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 pull-right"
                    disabled={false}
                    onClick={this.props.newStockOrder}/>
              <GsgButton text="AI Stock Order Assistant"
                    className="btn gems_custom_button gems_orange_button btn-default add-margin-left-0 pull-right me-2"
                    disabled={false}
                    onClick={this.props.uploadStockOrder}/>
              </div>
            ) : (
              <div className="pt-2 mt-3"/>
            )}
          </div>
        </div>
    )
  }
}


export default Footer